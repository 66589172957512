'use strict'

import * as coreCommon from 'assets/core/js/common'
import Autocomplete, { AutocompleteConfig, AutocompleteResultValue } from 'assets/core/js/module/autocomplete'
import MobileBottomPanel, { Events as MobileBottomPanelEvents } from '@ui/MobileBottomPanel/component'
import Tabs from '@ui/Tabs/component'
import setEvents from '@design/objects/fields/field/assets/setEvents'

import type { MobileBottomPanelType } from '@ui/MobileBottomPanel/component'

interface SearchAutocompleteResultValue extends AutocompleteResultValue {
  geo: AutocompleteResultValue[]
  product: AutocompleteResultValue[]
  meta: {
    context: Record<string, string>
    name: string
    type: string
  }
}

interface SearchAutocompleteResult {
  data: SearchAutocompleteResultValue
  html: string
}

interface SearchAutocompleteJsonData extends Omit<AutocompleteResultValue, 'id' | 'type'> {
  token: string
  detail: {
    id: number
    category: string
    source?: string
  }
}

export const Events = {
  AUTOCOMPLETE_SET_DATA: 'autocomplete.set.data',
  AUTOCOMPLETE_MODAL_OPENED: 'autocomplete.modal.opened',
  AUTOCOMPLETE_MODAL_CLOSED: 'autocomplete.modal.closed',
  AUTOCOMPLETE_ITEM_SELECTED: 'autocomplete.item.selected',
}

export class SearchAutocomplete extends Autocomplete {
  element!: Element
  mobileBottomPanel!: MobileBottomPanelType
  dataEl!: HTMLInputElement
  mirrorInputEl!: HTMLInputElement
  events!: {
    inputClick?(e?: MouseEvent | TouchEvent | Event): void
    inputKeyUp?(e?: KeyboardEvent | TouchEvent | Event): void
    inputInput?(): void
    searchInputKeyUp?(e?: KeyboardEvent | TouchEvent | Event): void
  }

  constructor(element: string | HTMLElement, userConfig?: AutocompleteConfig) {
    const config: Partial<AutocompleteConfig> = {
      callbackSelect: (value: SearchAutocompleteResultValue) => {
        if (value.type !== 'query') {
          this.dataEl.value = JSON.stringify(value)
        }

        if (value.type === 'query') {
          this.dataEl.value = ''
        }

        this.element.dispatchEvent(
          new CustomEvent(Events.AUTOCOMPLETE_ITEM_SELECTED, {
            detail: {
              item: value,
            },
          })
        )
      },
      callbackEmptyInput: () => {
        this.dataEl.value = ''
      },
      callbackOpen: () => {
        document.body.classList.add('search-form__autocomplete--opened')

        this.element.querySelectorAll<HTMLElement>('[data-tabIndex]').forEach((el) => {
          el.setAttribute('tabIndex', el.getAttribute('data-tabIndex') as string)
        })
      },
      callbackClose: () => {
        const currentlyOpened = this.element.classList.contains('autocomplete-active')
        document.body.classList.remove('search-form__autocomplete--opened')
        this.element.classList.remove('autocomplete-active')

        this.element.querySelectorAll<HTMLElement>('[data-tabIndex]').forEach((el) => {
          el.setAttribute('tabIndex', '-1')
        })

        if (currentlyOpened) {
          this.element.dispatchEvent(new CustomEvent(Events.AUTOCOMPLETE_MODAL_CLOSED))
        }
      },
      callbackClick: () => {
        this.element.dispatchEvent(new CustomEvent(Events.AUTOCOMPLETE_MODAL_OPENED))
        if (coreCommon.isMobile()) {
          this.inputEl.blur()
          this.mobileBottomPanel.open()
        }
      },
      closeIfMinLengthBelow: false,
    }

    super(element, Object.assign({}, config, userConfig))
    this.dataEl = document.getElementById(`${this.inputEl.id}-data`) as HTMLInputElement
    this.setupMirrorInput()
    this.initMobileBottomPanel()
    this.createElements()

    if (this.inputEl.value !== '') {
      this.inputEl.dispatchEvent(new CustomEvent('change'))
    }
  }

  private setupMirrorInput(): void {
    this.mirrorInputEl = this.element.querySelector('.autocomplete-input-mirror input') as HTMLInputElement

    if (this.inputEl.value !== '') {
      this.mirrorInputEl.value = this.inputEl.value
    }

    this.mirrorInputEl.addEventListener('input', (e) => {
      this.inputEl.value = (e.target as HTMLInputElement).value

      const newEvent = new InputEvent('input', {
        bubbles: true,
        cancelable: true,
        data: (e as InputEvent).data,
      })

      this.inputEl.dispatchEvent(newEvent)
    })
  }

  private createElements(): void {
    const resetFieldEl = document.createElement('div')
    resetFieldEl.className = 'field__reset'

    const clearInput = (e: Event): void => {
      e.preventDefault()
      e.stopPropagation()

      this.inputEl.value = ''
      this.inputEl.dispatchEvent(new CustomEvent('change'))
    }

    resetFieldEl.addEventListener('click', clearInput)
    resetFieldEl.addEventListener('touchstart', clearInput)

    this.inputEl.parentElement?.append(resetFieldEl)
  }

  initEvents(): void {
    super.initEvents()

    setEvents(this.inputEl)

    this.inputEl.addEventListener('change', (e) => {
      this.mirrorInputEl.value = (e.target as HTMLInputElement).value
    })

    this.element.querySelector<HTMLElement>(`label[for="${this.inputEl.id}"]`)?.addEventListener('pointerup', () => {
      this.config.callbackClick && this.config.callbackClick()
    })

    // @ts-ignore
    this.inputEl.addEventListener(Events.AUTOCOMPLETE_SET_DATA, (e: CustomEvent<SearchAutocompleteJsonData>) => {
      const { token, name, nameHighlight, description, detail } = e.detail

      this.dataEl.value = JSON.stringify({
        token,
        name,
        nameHighlight,
        description,
        detail,
      })

      this.inputEl.value = name
    })

    this.events.searchInputKeyUp = (e: KeyboardEvent) => {
      const rows = this.resultsEl.getElementsByClassName('autocomplete-result')

      // arrow up key
      if (e.keyCode === 38 && this.selectedRow > -1 && this.resultsVisible) {
        // @ts-ignore
        rows[this.selectedRow--].classList.remove('autocomplete-result-focus')

        if (this.selectedRow > -1) {
          // @ts-ignore
          rows[this.selectedRow].classList.add('autocomplete-result-focus')

          // @ts-ignore
          this.inputEl.value = rows[this.selectedRow].getAttribute('data-value')
        }
        // arrow down key
      } else if (e.keyCode === 40 && this.selectedRow < rows.length - 1 && this.resultsVisible) {
        if (this.selectedRow > -1) {
          // @ts-ignore
          rows[this.selectedRow].classList.remove('autocomplete-result-focus')
        }

        // @ts-ignore
        rows[++this.selectedRow].classList.add('autocomplete-result-focus')
        // @ts-ignore
        this.inputEl.value = rows[this.selectedRow].getAttribute('data-value')
      } else {
        // @ts-ignore
        this.events.inputKeyUp(e)
      }
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.removeEventListener('keyup', this.events.inputKeyUp)
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.addEventListener('keyup', this.events.searchInputKeyUp)

    this.element.addEventListener(Events.AUTOCOMPLETE_ITEM_SELECTED, () => {
      if (coreCommon.isMobile() || coreCommon.isTablet()) {
        this.close()
        this.mobileBottomPanel.close()
      }
    })
  }

  displayResults(data: SearchAutocompleteResult): void {
    this.resultsEl.innerHTML = ''
    this.resultsVisible = true

    // force order of keys
    const dataKeys = ['geo', 'product']
    let hasResults = false

    dataKeys.forEach((key) => {
      const resultData = data.data
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (resultData && resultData[key] && Array.isArray(resultData[key]) && resultData[key].length > 0) {
        if (!hasResults) {
          hasResults = true
        }

        this.resultsEl.innerHTML = data.html

        this.resultsEl.querySelectorAll<HTMLElement>('.autocomplete-result').forEach((el) => {
          el.addEventListener('click', () => {
            const value = el.getAttribute('data-value') as string
            const type = el.getAttribute('data-type') as keyof SearchAutocompleteResultValue
            const index = el.getAttribute('data-index') as string | number
            this.inputEl.value = value

            if (this.config.callbackSelect) {
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              this.config.callbackSelect(type === 'meta' ? resultData[type] : resultData[type][index])
            }

            this.close()
          })
        })
      }
    })

    this.initTabs()

    if (hasResults) {
      if (this.inputEl.value !== '') {
        this.open()
      }
    }
  }

  private initMobileBottomPanel(): void {
    const mobileBottomPanelEl = document.getElementById(`${this.inputEl.id}-mobilebottompanel`)

    if (!mobileBottomPanelEl) {
      return
    }

    this.mobileBottomPanel = MobileBottomPanel(mobileBottomPanelEl)

    const autocompleteMirrorContainerEl = this.element.querySelector<HTMLElement>('.autocomplete-input-mirror')

    if (!this.mobileBottomPanel || !autocompleteMirrorContainerEl) {
      return
    }

    mobileBottomPanelEl.addEventListener(MobileBottomPanelEvents.MOBILE_BOTTOM_PANEL_CLOSED, () => {
      this.close()
    })

    mobileBottomPanelEl.addEventListener(MobileBottomPanelEvents.MOBILE_BOTTOM_PANEL_OPENED, () => {
      setTimeout(() => {
        this.mirrorInputEl.focus()
      }, 250)
    })
  }

  private initTabs(): void {
    const tabsEl = this.resultsEl.querySelector<HTMLElement>('.autocomplete-results-lists')

    if (!tabsEl) {
      return
    }

    Tabs(tabsEl)

    const firstTab = this.resultsEl.querySelector<HTMLElement>('.autocomplete-results-tab')

    if (firstTab) {
      firstTab.click()
    }
  }

  getQueryParameters(): Record<string, string> {
    return super.getQueryParameters()
  }
}

export default (element: string | HTMLElement, userConfig?: AutocompleteConfig): SearchAutocomplete => {
  return new SearchAutocomplete(element, userConfig)
}
