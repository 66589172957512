'use strict'

import * as common from 'assets/core/js/common'

export interface AutocompleteResultValue {
  type: string
  id: number
  name: string
  nameHighlight: string
  description: string
}

export interface AutocompleteResult {
  data: AutocompleteResultValue | AutocompleteResultValue[]
}

export interface AutocompleteConfig {
  callbackClose?(): void
  callbackEmptyInput?(): void
  callbackOpen?(): void
  callbackPreRender?(resultEl: HTMLDivElement, value: AutocompleteResultValue): HTMLDivElement
  callbackSelect?(value: AutocompleteResultValue): void
  callbackClick?(): void
  minLength?: number
  queryParameter?: string
  delay?: number
  dataSource?: string
  dataElement?: null | string | HTMLSelectElement
  closeIfMinLengthBelow?: boolean
  formatLabel?(result: { name: string }): string
}

export const Events = {
  AUTOCOMPLETE_OPENED: 'autocomplete.opened',
  AUTOCOMPLETE_CLOSED: 'autocomplete.closed',
  AUTOCOMPLETE_ITEM_SELECTED: 'autocomplete.item.selected',
}

const defaultConfig: AutocompleteConfig = {
  callbackClose: function () {
    /* nothing */
  },
  callbackEmptyInput: function () {
    /* nothing */
  },
  callbackOpen: function () {
    /* nothing */
  },
  callbackPreRender: function (resultEl) {
    /* istanbul ignore next */
    return resultEl
  },
  callbackSelect: function () {
    /* nothing */
  },
  callbackClick: function () {
    /* nothing */
  },
  minLength: 2,
  queryParameter: 'query',
  delay: 300,
  dataSource: 'xhr',
  dataElement: null,
  closeIfMinLengthBelow: true,
  formatLabel: function (result) {
    /* istanbul ignore next */
    return result?.name
  },
}

export default class Autocomplete {
  config!: AutocompleteConfig
  element!: Element
  selectEl!: HTMLSelectElement
  events!: {
    inputClick?(e?: MouseEvent | TouchEvent | Event): void
    inputKeyUp?(e?: KeyboardEvent | TouchEvent | Event): void
    inputInput?(): void
  }

  cache!: Record<string, AutocompleteResult>
  inputEl!: HTMLInputElement
  resultsEl!: Element
  selectedRow!: number
  searchValue!: string
  previousRequest!: null | XMLHttpRequest
  resultsVisible!: boolean
  onKeyUpTimeout!: ReturnType<typeof setTimeout>

  constructor(element: string | HTMLElement, userConfig: AutocompleteConfig) {
    let el: Element | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector(element)
    }

    if (!el || typeof el === 'string') {
      return
    }

    this.element = el
    this.config = Object.assign({}, defaultConfig, userConfig)
    this.config.callbackClose = this.config.callbackClose?.bind(this)
    this.config.callbackEmptyInput = this.config.callbackEmptyInput?.bind(this)
    this.config.callbackOpen = this.config.callbackOpen?.bind(this)
    this.config.callbackPreRender = this.config.callbackPreRender?.bind(this)
    this.config.callbackSelect = this.config.callbackSelect?.bind(this)
    this.config.callbackClick = this.config.callbackClick?.bind(this)

    this.events = {}
    this.cache = {}

    this.inputEl = this.element.querySelector('.autocomplete-field') as HTMLInputElement
    this.resultsEl = this.element.querySelector('.autocomplete-results') as HTMLElement
    this.selectedRow = -1
    this.searchValue = this.sanitize(this.inputEl.value)
    this.previousRequest = null
    this.resultsVisible = false

    if (this.config.dataSource === 'select' && this.config.dataElement) {
      this.initSelectEl()
    }

    document.body.addEventListener('click', (e) => {
      if (!this.element.contains(e.target as HTMLElement) && this.resultsVisible) {
        this.close()
      }
    })

    this.destroy = this.destroy.bind(this)
    this.initEvents()
  }

  initEvents(): void {
    this.events.inputClick = () => {
      this.config.callbackClick && this.config.callbackClick()

      if (this.cache[this.searchValue] && !this.resultsVisible) {
        // @ts-ignore
        this.displayResults(this.cache[this.searchValue])
      } else if (this.inputEl.value !== '' && this.searchValue !== this.inputEl.value) {
        this.query()
      }
    }

    this.events.inputKeyUp = (e: KeyboardEvent) => {
      const rows = this.resultsEl.getElementsByClassName('autocomplete-result')

      // arrow up key
      if (e.keyCode === 38 && this.selectedRow > -1 && this.resultsVisible) {
        // @ts-ignore
        rows[this.selectedRow--].classList.remove('autocomplete-result-focus')

        if (this.selectedRow > -1) {
          // @ts-ignore
          rows[this.selectedRow].classList.add('autocomplete-result-focus')
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          this.inputEl.value = this.cache[this.searchValue].data[this.selectedRow].name
        }
        // arrow down key
      } else if (e.keyCode === 40 && this.selectedRow < rows.length - 1 && this.resultsVisible) {
        if (this.selectedRow > -1) {
          // @ts-ignore
          rows[this.selectedRow].classList.remove('autocomplete-result-focus')
        }

        // @ts-ignore
        rows[++this.selectedRow].classList.add('autocomplete-result-focus')
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        this.inputEl.value = this.cache[this.searchValue].data[this.selectedRow].name
        // enter key
      } else if (e.keyCode === 13 && this.resultsVisible) {
        if (rows[this.selectedRow]) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          rows[this.selectedRow].click()
        }
        // escape key
      } else if (e.keyCode === 27) {
        if (this.resultsVisible) {
          this.close()
        }
      }
    }

    this.events.inputInput = () => {
      // @ts-ignore
      if (this.config.minLength <= this.inputEl.value.length && this.searchValue !== this.inputEl.value) {
        this.resultsEl.innerHTML = ''
        this.searchValue = this.sanitize(this.inputEl.value)
        this.selectedRow = -1

        if (this.cache[this.searchValue]) {
          // @ts-ignore
          this.displayResults(this.cache[this.searchValue])
        } else {
          clearTimeout(this.onKeyUpTimeout)

          this.onKeyUpTimeout = setTimeout(() => {
            if (this.previousRequest && this.previousRequest.readyState < XMLHttpRequest.DONE) {
              this.previousRequest.abort()
            }

            // @ts-ignore
            if (this.config.minLength <= this.inputEl.value.length && this.searchValue !== '') {
              this.query()
            }
          }, this.config.delay)
        }
        // @ts-ignore
      } else if (this.config.minLength > this.inputEl.value.length) {
        this.searchValue = this.sanitize(this.inputEl.value)

        if (this.resultsVisible && this.config.closeIfMinLengthBelow) {
          this.close()
        } else {
          this.hide()
        }

        if (this.searchValue.length === 0) {
          this.resultsEl.innerHTML = ''
          // @ts-ignore
          this.config.callbackEmptyInput()
        }
      }
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.addEventListener('pointerup', this.events.inputClick)
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.addEventListener('keyup', this.events.inputKeyUp)
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.addEventListener('input', this.events.inputInput)
    this.inputEl.addEventListener('clear', () => {
      if (this.inputEl.value === '') {
        // @ts-ignore
        this.config.callbackEmptyInput()
        this.resultsEl.innerHTML = ''
      }
    })
  }

  sanitize(content: string): string {
    if (content) {
      const node = document.createElement('div')
      node.innerHTML = content

      return node.textContent || ''
    }

    return ''
  }

  destroy(): void {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.removeEventListener('touchstart', this.events.inputClick)
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.removeEventListener('click', this.events.inputClick)
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.removeEventListener('keyup', this.events.inputKeyUp)
  }

  initSelectEl(): void {
    if (typeof this.config.dataElement === 'string') {
      this.selectEl = document.querySelector(this.config.dataElement) as HTMLSelectElement
    } else {
      this.selectEl = this.config.dataElement as HTMLSelectElement
    }

    if (!this.selectEl) {
      throw new Error('Data source element not found.')
    }

    if (this.selectEl.nodeName !== 'SELECT') {
      throw new Error('Data source is not a select.')
    }

    if (this.selectEl?.options[this.selectEl.selectedIndex]?.value) {
      this.inputEl.value = this.selectEl?.options[this.selectEl.selectedIndex]?.text as string
    }
  }

  query(): void {
    let url = this.inputEl.getAttribute('data-url')
    const loadingMessage = this.inputEl.getAttribute('data-loading-message')

    this.resultsVisible = true

    if (loadingMessage && !this.resultsEl.querySelector('.autocomplete-result-message')) {
      const loadingEl = document.createElement('div')
      loadingEl.className = 'autocomplete-result-message'
      loadingEl.innerHTML = loadingMessage
      this.resultsEl.innerHTML = ''
      this.resultsEl.appendChild(loadingEl)

      this.open()
    }

    if (this.config.dataSource === 'xhr' && url) {
      const queryStringParams = []
      const params = this.getQueryParameters()
      for (const name in params) {
        if (!Object.prototype.hasOwnProperty.call(params, name)) {
          continue
        }
        queryStringParams.push(`${name}=${encodeURIComponent(params[name] as string)}`)
      }
      url = url + (url.indexOf('?') === -1 ? '?' : '&') + queryStringParams.join('&')

      const xhr = common.createXhr('GET', url)

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          try {
            if (typeof xhr.response === 'string') {
              const data = JSON.parse<AutocompleteResult>(xhr.response)

              this.displayResults(data)
              this.cache[this.searchValue] = data
            }
          } catch (exception) {
            this.displayNoResults()
          }
        } else {
          this.displayNoResults()
        }
      }

      xhr.send(null)

      this.previousRequest = xhr
    } else if (this.config.dataSource === 'select') {
      let data: AutocompleteResultValue[] = []

      try {
        // @ts-ignore
        data = this.parseSelectDataSource().filter((obj) => {
          return obj.name.match(new RegExp(this.searchValue, 'i'))
        })
      } catch (exception) {
        data = []
      }

      this.displayResults({
        data,
      })
    }
  }

  getQueryParameters(): Record<string, string> {
    const params: Record<string, string> = {}
    params[this.config.queryParameter as string] = this.searchValue

    return params
  }

  parseSelectDataSource(): { value: string; name: string }[] {
    const data: { value: string; name: string }[] = []
    const options = this.selectEl.querySelectorAll('option')

    options.forEach((option) => {
      data.push({
        value: option.value,
        name: option.text,
      })
    })

    return data
  }

  displayResults(data: AutocompleteResult): void {
    this.resultsEl.innerHTML = ''

    this.resultsVisible = true

    if (data.data && Array.isArray(data.data) && data.data.length > 0) {
      data.data.forEach((value) => {
        if (value.name) {
          let resultEl = document.createElement('div')
          resultEl.className = 'autocomplete-result'
          resultEl.innerHTML = value.nameHighlight

          resultEl.addEventListener('click', () => {
            if (this.config.formatLabel) {
              this.inputEl.value = this.config.formatLabel(value)
            }
            this.selectedRow = -1

            if (this.config.callbackSelect) {
              this.config.callbackSelect(value)
            }

            this.close()
          })

          resultEl.addEventListener('mouseover', (e) => {
            const focusedEl = this.resultsEl.getElementsByClassName('autocomplete-result-focus')[0]
            if (focusedEl && e.target !== focusedEl) {
              focusedEl.classList.remove('autocomplete-result-focus')
            }

            resultEl.classList.add('autocomplete-result-focus')

            this.selectedRow = -1
          })

          if (this.config.callbackPreRender) {
            resultEl = this.config.callbackPreRender(resultEl, value) ?? resultEl
          }

          this.resultsEl.appendChild(resultEl)
        }
      })

      if (this.inputEl.value !== '') {
        this.open()
      }
    } else {
      this.displayNoResults()
    }
  }

  displayNoResults(): void {
    this.resultsVisible = true

    const noResultsMessage = this.inputEl.getAttribute('data-no-results-message') as string
    const noResultEl = document.createElement('div')
    noResultEl.className = 'autocomplete-result-message'
    noResultEl.innerHTML = noResultsMessage
    this.resultsEl.innerHTML = ''
    this.resultsEl.appendChild(noResultEl)

    this.open()
  }

  hide(): void {
    this.resultsEl.classList.add('autocomplete-results-hidden')
  }

  open(): void {
    this.resultsEl.classList.remove('autocomplete-results-hidden')

    if (this.config.callbackOpen) {
      this.config.callbackOpen()
    }

    this.element.dispatchEvent(new CustomEvent(Events.AUTOCOMPLETE_OPENED))
  }

  close(): void {
    this.hide()
    this.resultsVisible = false

    if (this.config.callbackClose) {
      this.config.callbackClose()
    }

    this.element.dispatchEvent(new CustomEvent(Events.AUTOCOMPLETE_CLOSED))
  }
}
