'use strict'

import SearchAutocomplete from '@ui/Search/Form/Fields/Autocomplete/component'

import type { SearchAutocomplete as SearchAutocompleteType } from '@ui/Search/Form/Fields/Autocomplete/component'

export default function (): SearchAutocompleteType | boolean {
  const fieldEl = document.querySelector<HTMLElement>('#search-offers .search-form-autocomplete')
  if (fieldEl) {
    return SearchAutocomplete(fieldEl)
  }

  return false
}
